import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

declare var fbq: Function;

@Injectable({
    providedIn: 'root'
})
export class MetaPixelService {
    constructor(@Inject(DOCUMENT) private document: Document) {}
   
    removeFacebookSDK() {
        if (this.document.getElementById('test678') && this.document.getElementById('test123')) {
          this.document.getElementById('test123')?.remove()
          this.document.getElementById('test678')?.remove()
          return;
        } 
      }
    
      create() {
        const fbqScript = this.document.createElement('script')
        const stripeScriptUrl = "https://connect.facebook.net/en_US/sdk.js";
          const scriptEl = this.document.createElement('script');
          scriptEl.src = stripeScriptUrl;
          scriptEl.id = 'test123'
          this.document.body.appendChild(scriptEl);

        const temp = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '490038058599776');`

          fbqScript.innerHTML = temp
          this.document.body.appendChild(fbqScript);
      }

    public productDeail(content_ids:string[],) {
        fbq('track', 'ViewContent', {
            content_ids: content_ids, // 'REQUIRED': array of product IDs
            content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
          });
    }

    public basket(userId:string,content_ids:string[]) {
        fbq('track', 'add_to_cart_web', {
            user_id:userId,
            content_ids: content_ids, // 'REQUIRED': array of product IDs
            content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
          });
    }

    public oderComplate(content_ids:string[],price:number) {
        fbq('track', 'Purchase', {
            content_ids: content_ids, // 'REQUIRED': array of product IDs
            value: price, // REQUIRED, up to 2 decimals optional
            currency: 'USD', // REQUIRED
            content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
          });
    }
    // Sign In & Sign Up Events 
    public onSignInEvent(event:any) {
        fbq('trackCustom', 'Sign In', {
            // user_email: event
        });
    }

    public onSignUpEvent(userId:string) {
        fbq('trackCustom', 'sign_up_web', {
            user_id:userId
            // user_email: event
        });
    }

    public freeTrial(userId:string) {
        fbq('trackCustom', 'free_trial_web', {
            user_id:userId
            // user_email: event
        });
    }

    public onHostSignUpEvent(event:any) {
        fbq('trackCustom', 'Host Sign Up', {
            // host_email: event
        });
    }
    // Sign In & Sign Up Events 

    // Verification
    public onSMSVerificationEvent(event:any) {
        fbq('trackCustom', 'SMS Verification', {
            // user_email: event
        })
    }
    public onEmailVerificationEvent(event:any) {
        fbq('trackCustom', 'Email Verification', {
            // user_email: event
        })
    }
    // Verification

    // Pricing Calculation
    public onPricingCalculation(event:any) {
        fbq('trackCustom', 'Shipping Price Calculation', {
            country_from: event.country_from,
            country_to: event.country_to,
            // user_email: event.user_email ? event.user_email : 'User Not Logged In'
        })
    }
    // Pricing Calculation




    // Package Order Events 
    public onGetAddressSuccessEvent(event:any) {
        fbq('trackCustom', 'Get Address Success', {
            country_from: event.country_from,
            country_to: event.country_to,
            country_from_name: event.country_from_name,
            country_to_name: event.country_to_name,
            // shopper_email: event.shopper_email,
            invoice_expected_amount: event.invoice_expected_amount,
            dimension_expected_weight: event.dimension_expected_weight,
            dimension_expected_weight_unit: event.dimension_expected_weight_unit,
            product_link: event.product_link
        });
    }

    public onOrderPaymentStepOne(event:any) {

        fbq('trackCustom', 'Package Order Confirmation - Order Contents', {
            order_id: event.order_id,
            country_from: event.country_from,
            country_from_name: event.country_from_name,
            country_to: event.country_to,
            country_to_name: event.country_to_name,
            product_link: event.product_link,
            invoice_actual_amount: event.invoice_actual_amount,
            dimension_actual_weight: event.dimension_actual_weight,
            dimension_actual_weight_unit: event.dimension_actual_weight_unit
        })
    }
    public onOrderPaymentStepTwo(event:any) {
        fbq('trackCustom', 'Package Order Confirmation - Address Selection', {
            order_id: event.order_id,
            country_from: event.country_from,
            country_from_name: event.country_from_name,
            country_to: event.country_to,
            country_to_name: event.country_to_name,
            product_link: event.product_link,
            invoice_actual_amount: event.invoice_actual_amount,
            dimension_actual_weight: event.dimension_actual_weight,
            dimension_actual_weight_unit: event.dimension_actual_weight_unit
        })
    }

    public onOrderSuccessEvent(event:any) {
        fbq('trackCustom', 'Order Success', {
            order_id: event.order_id,
            country_from: event.country_from,
            country_from_name: event.country_from_name,
            country_to: event.country_to,
            country_to_name: event.country_to_name,
            product_link: event.product_link,
            invoice_actual_amount: event.invoice_actual_amount,
            dimension_actual_weight: event.dimension_actual_weight,
            dimension_actual_weight_unit: event.dimension_actual_weight_unit,
            amount: event.amount,
            // shopper_email: event.shopper_email
        });
    }
    // Package Order Events 

    // BFM Events
    public onBFMCheckoutEvent(event:any) {
        fbq('trackCustom', 'BFM Checkout', {
            country_from: event.country_from,
            country_to: event.country_to,
            bfm_items: event.items,
            shopper: event.shopper_id,
            // shopper_email: event.shopper_email
        });
    }
    public onBFMSuccessEvent(event:any) {
        fbq('trackCustom', 'BFM Order Success', {
            country_from: event.country_from,
            country_from_name: event.country_from_name,
            country_to: event.country_to,
            country_to_name: event.country_to_name,
            bfm_items: event.items,
            bfm_total: event.bfm_total,
            bfm_currency: event.bfm_currency,
            // shopper_email: event.email
        });
    }
    // BFM Events 

    public onBFMExtraChargeSuccess(event:any){
        fbq('trackCustom', 'BFM Extra Charge Success', {
            country_from: event.country_from,
            country_from_name: event.country_from_name,
            country_to: event.country_to,
            country_to_name: event.country_to_name,
            bfm_items: event.items,
            bfm_total: event.bfm_total,
            bfm_currency: event.bfm_currency,
            // shopper_email: event.email
        });
    }



}